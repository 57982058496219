// import "@fortawesome/fontawesome-svg-core/styles.css"

import React from 'react'

import { CartItemsProvider } from "./src/context/CartItemsContext"

// exports.shouldUpdateScroll = () => false

export const wrapRootElement = ({ element }) => (
  <CartItemsProvider>{element}</CartItemsProvider>
)


// export const onRouteUpdate = ({ location, prevLocation }) => {
//   console.log('new pathname', location.pathname)
//   console.log('old pathname', prevLocation ? prevLocation.pathname : null)
//
//   // const abc = prevLocation ? prevLocation.pathname : null
//   if (prevLocation ? prevLocation.pathname.includes('karte') : null) {
//     console.log('stimmt')
//   }
// }
